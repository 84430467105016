import React, { useState } from 'react';
import { Alert, Button, Col, FormControl, InputGroup, Row, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { renderPagination } from "../../utils/config";

const TitlesList = ({ titles, handleShowCountriesModal, handleShowAuthClientModal, handleShowViewAuthClientModal }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const titlesPerPage = 10; // This can be a constant if it doesn't change
    const [filter, setFilter] = useState('');

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
        setCurrentPage(1); // Reset to first page on filter change
    };

    const indexOfLastTitle = currentPage * titlesPerPage;
    const indexOfFirstTitle = indexOfLastTitle - titlesPerPage;

    // Filter titles based on the studio input
    const filteredTitles = filter
        ? titles.filter((title) => title.studio.toLowerCase() === filter.toLowerCase())
        : titles;

    const currentTitles = filteredTitles.slice(indexOfFirstTitle, indexOfLastTitle);
    const totalPages = Math.ceil(filteredTitles.length / titlesPerPage);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <>
            <Row className="mb-3">
                <Col xs={12} md={4}>
                    <InputGroup>
                        <InputGroup.Text id="search">
                            <FontAwesomeIcon icon={faFilter} />
                        </InputGroup.Text>
                        <FormControl
                            type="text"
                            placeholder="Filter by Studio"
                            value={filter}
                            onChange={handleFilterChange}
                        />
                    </InputGroup>
                </Col>
            </Row>
            {filteredTitles.length === 0
                ? <Alert variant="info">No titles available.</Alert>
                : (
                    <>
                        <Table striped borderless hover className='list-table'>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Studio</th>
                                    <th>Name</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentTitles.map((title) => (
                                    <tr key={title.title_id}>
                                        <td>{title.titleId}</td>
                                        <td>{title.studio}</td>
                                        <td>{title.title}</td>
                                        <td>
                                            <Button variant="outline-dark" size="sm" onClick={() => handleShowCountriesModal(title)}>Select Unavailable Countries</Button>
                                            <Button variant="outline-dark" size="sm" className='mx-3' onClick={() => handleShowAuthClientModal(title)}>Configure AuthClient</Button>
                                            <Button variant="outline-dark" size="sm" className='mx-3' onClick={() => handleShowViewAuthClientModal(title)}>View/Edit AuthClients</Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <Row className="mt-3">
                            <Col className="d-flex justify-content-center">
                                {renderPagination(currentPage, totalPages, paginate)}
                            </Col>
                        </Row>
                    </>
                )}
        </>
    );
};

export default TitlesList;