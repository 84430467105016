export const apiRequest = async (url, method, csrfToken, body = null) => {
    const headers = {
        'X-XSRF-TOKEN': csrfToken,
        'Content-Type': 'application/json',
    };

    const options = {
        method,
        headers,
        credentials: 'include',
    };

    if (body) {
        options.body = JSON.stringify(body);
    }

    const response = await fetch(url, options);
    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to process the request');
    }
    return await response.json();
};
