import { useState, useEffect } from 'react';
import { Modal, Button, Spinner, Alert, Form, FormControl, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import './SelectCountriesModal.scss';

function SelectCountriesModal({ show, handleClose, title, titleId }) {
    const [countries, setCountries] = useState([]);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [loading, setLoading] = useState(true);
    const [fetchCountriesError, setFetchCountriesError] = useState(false);
    const [saving, setSaving] = useState(false);
    const [saveError, setSaveError] = useState(null);
    const [search, setSearch] = useState('');
    const csrfToken = sessionStorage.getItem('csrfToken');
    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {

        const fetchCountries = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_URL}/v1/admin/countries`, {
                    headers: {
                        'X-XSRF-TOKEN': csrfToken,
                        'Content-Type': 'application/json'
                    },
                    credentials: 'include',
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch countries');
                }
                const data = await response.json();
                //setFetchCountriesError(false);
                setCountries(data);
            } catch (error) {
                setFetchCountriesError(true);
            } finally {
                setLoading(false);
            }
        };

        const fetchUnavailableCountries = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_URL}/v1/admin/titles/${titleId}`, {
                    headers: {
                        'X-XSRF-TOKEN': csrfToken,
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch unavailable countries');
                }
                const data = await response.json();
                setSelectedCountries(data.unavailableCountries || []);
            } catch (error) {
                setFetchCountriesError(error.message);
            } finally {
                setLoading(false);
            }
        };

        if (show) {
            fetchCountries();
            fetchUnavailableCountries();
        }
    }, [show, titleId]);

    const handleCheckboxChange = (countryCode) => {
        setSelectedCountries((prevSelected) =>
            prevSelected.includes(countryCode)
                ? prevSelected.filter((code) => code !== countryCode)
                : [...prevSelected, countryCode]
        );
    };

    const handleSaveUnavailableCountries = async () => {
        setSaving(true);
        setSaveError(null);
        try {
            const response = await fetch(`${API_URL}/v1/admin/titles/${titleId}`, {
                method: 'PUT',
                headers: {
                    'X-XSRF-TOKEN': csrfToken,
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ unavailableCountries: selectedCountries }),
            });
            if (!response.ok) {
                throw new Error('Failed to save selected countries');
            }
            handleClose();
        } catch (error) {
            setSaveError(error.message);
        } finally {
            setSaving(false);
        }
    };

    const filteredCountries = countries.filter((country) =>
        country.name.toLowerCase().includes(search.toLowerCase())
    );

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='custom-modal-body'>
                {loading ? (
                    <Spinner animation="border" role="status" data-testid="loading-spinner">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                ) : (
                    <>
                        {fetchCountriesError ? (
                            <Alert variant="danger">
                                Unable to load the list of countries. Please try again later.
                            </Alert>
                        ) : (
                            <>
                                <InputGroup>
                                    <InputGroup.Text id="search">
                                        <FontAwesomeIcon icon={faSearch} />
                                    </InputGroup.Text>
                                    <FormControl
                                        type="text"
                                        aria-label='search-text'
                                        placeholder="Search countries"
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                </InputGroup>

                                <div className="modal-form-control mt-3">
                                    <div className="modal-form-control-inner">
                                        <Form>
                                            {filteredCountries.map((country) => (
                                                <Form.Check
                                                    key={country.country_id}
                                                    type="checkbox"
                                                    className='mb-3'
                                                    id={`country-${country.country_id}`}
                                                    checked={selectedCountries.includes(country.country_code)}
                                                    onChange={() => handleCheckboxChange(country.country_code)}
                                                    label={
                                                        <div>
                                                            <span>{country.name}</span>
                                                            <span className="country-code">({country.country_code})</span>
                                                        </div>
                                                    }
                                                />
                                            ))}
                                        </Form>
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                )}
                {saveError && <Alert variant="danger" className="mt-3">{saveError}</Alert>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose} disabled={saving}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSaveUnavailableCountries} disabled={saving}>
                    {saving ? 'Saving...' : 'Save'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default SelectCountriesModal;
