import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useAuth = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const csrfToken = sessionStorage.getItem('csrfToken');
        if (!csrfToken) {
            navigate('/', { state: { message: 'Please log in first.' } });
        }
    }, [navigate]);
};

export default useAuth;
